<template>
  <v-container class="pa-0" id="ModalNegociacaoCabecalho" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
        <v-container class="mb-n1" style="margin: 0px; padding: 0px; width: 100%">
          <v-layout>
              <div 
                v-if="store_ModalNegociacao.dados.cod_imovel"
                class="pa-1"
                style="width:130px;">
                <v-avatar
                  size="120"
                  rounded
                  style="background-color: #909090; top: 0px; left: 0px"
                >
                  <v-avatar
                    size="118"
                    rounded
                    style="top: 0px; left: 0px"
                  >
                  <v-img v-bind:src="ImagemCapaCaminhoCompleto"></v-img>
                  </v-avatar>
                </v-avatar>
              </div>
              <div 
                v-if="!store_ModalNegociacao.dados.cod_imovel && store_ModalNegociacao.dados.cod_empreendimento"
                class="pa-1"
                style="width:130px;">
                <v-avatar
                  size="120"
                  rounded
                  style="background-color: transparent; top: 0px; left: 0px"
                >
                  <v-avatar
                    size="auto"
                    rounded
                    style="top: 0px; left: 0px"
                  >
                  <v-img v-bind:src="ImagemCapaCaminhoCompleto"></v-img>
                  </v-avatar>
                </v-avatar>
              </div>
              <div class="pa-1" style="width:100%">
                <v-layout nowrap style="max-height:42px; width:100%">
                  <div class="pa-0" style="width:100%">
                    <p style="color:white; white-space: pre-line; font-size:14px !important;">
                      {{ store_ModalNegociacao.dados.empreend_nome }}
                    </p>                      
                  </div>
                  <div class="pa-0 text-center" style="width:40px;">
                    <v-btn icon dark v-on:click="MapaLotes_FechaDialogo()" class="mt-n3">
                      <v-icon large class="title">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-layout>
                <v-layout>
                  <div class="pa-0" style="min-height:100px;">
                    <div class="pa-0 " style="height:28px;">
                      <span class="sub-title">Tipo: </span>
                      <span class="ti-value"
                        v-if="store_ModalNegociacao.dados.imovel_tipo">
                        {{ store_ModalNegociacao.dados.imovel_tipo.nome }}
                      </span><br>
                    </div>
                    <div 
                      v-if="!store_ModalNegociacao.dados.cod_imovel && store_ModalNegociacao.dados.cod_empreendimento"
                      class="pa-0"
                      style="height:28px;">
                      <span class="sub-title">Cidade: </span>
                      <span class="ti-value"> {{ store_ModalNegociacao.dados.cidade }}</span><br>
                    </div>
                    <div 
                      v-if="store_ModalNegociacao.dados.cod_imovel"
                      class="pa-0"
                      style="height:28px;">
                      <span class="sub-title" >Quadra: </span>
                      <span class="ti-value"> {{ store_ModalNegociacao.dados.quadra }}</span>
                      <span class="sub-title" style="margin-left:15px">Lote: </span>
                      <span class="ti-value"> {{ store_ModalNegociacao.dados.lote }}</span>
                    </div>
                    <div
                      v-if="store_ModalNegociacao.dados.cod_imovel && store_usuario.autenticado"
                      class="pa-0"
                      style="height:28px;"
                      :key="store_ModalNegociacao.key_valor">
                      <span class="sub-title">Valor: </span>
                      <span class="ti-value" v-if="store_ModalNegociacao.dados.preco_final && store_ModalNegociacao.dados.preco_final > 0 && store_ModalNegociacao.dados.preco_total != store_ModalNegociacao.dados.preco_final" style="text-decoration:line-through; color:#C0C0C0">R$ {{ formatNumber(store_ModalNegociacao.dados.preco_total) }}</span>
                      <span class="ti-value" v-else >R$ {{ formatNumber(store_ModalNegociacao.dados.preco_final) }}</span>
                      <span class="ti-value" v-if="store_ModalNegociacao.dados.preco_final && store_ModalNegociacao.dados.preco_final > 0 && store_ModalNegociacao.dados.preco_total != store_ModalNegociacao.dados.preco_final">R$ {{ formatNumber(store_ModalNegociacao.dados.preco_final) }}</span>
                    </div>
                    <div
                      v-if="store_ModalNegociacao.dados.cod_imovel && !store_usuario.autenticado"
                      class="pa-0"
                      style="height:28px;">
                      <span class="ti-value"> <v-icon class="icon-help">mdi-help-circle-outline</v-icon></span>
                    </div>
                  </div>
                </v-layout>
              </div>
          </v-layout>
      </v-container>
    <!-- End Modal Header -->
  </v-container>

</template>

<script type="text/javascript">
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_usuario from "../../store/store_usuario";
import { formatNumber } from "../../services/funcoes";
import { EventBus } from "../../services/eventbus";
import { baseURL } from "../../services/API";
import { COR_PRINCIPAL } from "../../services/constantes";

export default {
  name: "ModalNegociacaoCabecalho",

  data() {
    return {
      formatNumber          : formatNumber,
      store_usuario         : store_usuario,
      store_ModalNegociacao : store_ModalNegociacao,
      COR_PRINCIPAL         : COR_PRINCIPAL,
    };
  },

  computed: {
      ImagemCapaCaminhoCompleto: function () {
        var img = "";
        if (this.store_ModalNegociacao.dados)
          if (this.store_ModalNegociacao.dados.imagem_capa_caminho)
            img = baseURL + this.store_ModalNegociacao.dados.imagem_capa_caminho;
          else if (this.store_ModalNegociacao.dados.empreend_logo)
            img = this.store_ModalNegociacao.dados.empreend_logo;
        return img
    }
  },

  mounted() {
    //console.log("Cabecalho", this.store_ModalNegociacao.dialog);
  },

  methods: {
    async MapaLotes_FechaDialogo() {
      this.store_ModalNegociacao.dialog = false;  
      this.store_ModalNegociacao.dialog_novo_lead = false;  

      //EventBus.$emit("MapaLotes_FechaDialogo");
    },

  }

};
</script>

<style scoped>

.title-value {
  font-size: 15px !important;
  color: #fff;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  background-color:#a1c6fa;  
}

.icon-help {
  font-size: 1.4rem!important;
  color: #fff;
  opacity: .8;
}

.modal-title {
  font-size: 16px!important;
}

.sub-title {
  font-size: 14px!important;
  color:#a1c6fa;
  margin-left: 0px;
}

.ti-value {
  font-size: 15px !important;
  color: #fff;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

</style>
