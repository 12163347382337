<template>
  <v-container class="pa-0" id="ModalSelecaoCliente">

    <!-- Begin Componente Usuarios -->
    <v-container class="pa-0">
      <PessoaConsultaMobile />
    </v-container>
    <!-- End Componente Usuarios -->

    <!-- Modal Footer -->
    <v-footer absolute class="pa-2 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn
        v-if="ls_Anterior"
        color="primary"
        class="mr-2"
        v-on:click="store_ModalNegociacao.currentTransition='prev';store_ModalNegociacao.step = ls_Anterior"
        outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>
      
      <v-btn color="primary"
              class="ml-2"
              :disabled="!store_ModalNegociacao.pessoa_selecionado || store_ModalNegociacao.pessoa_selecionado.length <= 0"
              v-on:click="Proximo()"
              :loading="loading"
      >
        <v-icon left>
          mdi-arrow-right
        </v-icon>
        Próximo
      </v-btn>
      <!-- v-on:click="validate" -->
    </v-footer>

    <!-- End Modal Footer -->
  </v-container>

</template>

<script type="text/javascript">
import Vue from "vue";
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Pessoa from "../Pessoas/store_Pessoa";
import store_Corretor from "../Corretores/store_Corretor"
import PessoaConsultaMobile from "../Pessoas/PessoaConsultaMobile.vue"

export default {
  name: "ModalSelecaoPessoa",

  props: ['ls_Anterior', 'ls_Proximo'],

  components: {
    PessoaConsultaMobile
  },

  data() {
    return {
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Pessoa                : store_Pessoa,
      store_Corretor              : store_Corretor,

      timeOut                 : 0,
      loading                 : false,
    };
  },

  created() {
    // console.log('created',this.ls_Anterior, this.ls_Proximo);
    //this.ls_Anterior = 'ModalNegociar'
    //this.ls_Proximo  = 'ModalSelecaoPagamento'
  },
  mounted(){
    // console.log('mounted');
  },

  methods: {
    Proximo() {
      this.store_ModalNegociacao.currentTransition='next';
      // if (this.store_usuario.tipo_cliente == 'USUARIO') {
      //   console.log('Tentando Setar 1', this.ls_Proximo)
      //   //Vue.set(this.ls_Proximo, 'ModalSelecaoCorretor')
      //   //this.ls_Proximo = 'ModalSelecaoCorretor';
      // }
      this.store_ModalNegociacao.step = this.ls_Proximo;
    },

  }

};

</script>

<style scoped>
  *,
  html {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .modal-wrapper {
    overflow-y: auto;
    height: 400px;
  }

  .title-value {
    font-size: 18px !important;
    font-weight: 800;
    color: steelblue;
  }

  .title-value-light {
    font-size: 18px !important;
    font-weight: 500;
    color:steelblue;
  }

  .title-debt {
    font-size: 18px !important;
    font-weight: 800;
    color: #f03347;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .v-btn {
    width: 150px;
  }

  .card-row {
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    padding: 16px;
  }

  .card-title {
    font-size: 17px !important;
    font-weight: 600;
    text-transform: capitalize !important;
    color: #444;
  }

  .container-table-contact {
    background: aqua!important;
    padding: 20px;
  }

  .tabela {
    width: 400px!important;
    background: aqua!important;
  }

  .row-container-contact {
    width: 100%!important;
    padding: 20px!important;
    background: aqua!important;
  }

</style>
