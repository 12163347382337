<template>
  <div id="ModalPropostaSucesso" class="p-0" style="min-height: 400px">
    <!-- Modal Content -->
    <v-layout row wrap class="modal-container w-100 ml-0 pt-4 px-4 mb-3">
      <v-container class="modal-wrapper w-100 py-6 rounded white">
        <h2
          align="center"
          style="color: #5cb460"
          class="intro font-weight-bold"
        >
          Proposta gerada com Sucesso!
        </h2>
        <div
          class="container-check d-flex justify-center align-center mt-6 mx-auto"
        >
          <v-icon class="icon-check" color="green">mdi-check-bold</v-icon>
        </div>
      </v-container>

      <v-container class="pt-7">
        <!-- <p class="text">
          Aproveite para enviar seus documentos acessando o menu "Minhas Negociações" na parte infeiro esquerda do site ou no menu do seu usuário.
        </p> -->
        <p class="text">
          Sua proposta será analizada pela área administrativa.
        </p>

        <br>

        <!-- Modal Footer -->
        <v-container class="px-4 text-none">
          <br><br>

          <v-btn
            class="btn-anexo text-white text-none"
            color="primary"
            v-on:click="propostaRelatorio(store_ModalNegociacao.cod_opor)"
            :loading="loading"
          >
          <v-icon left dark>mdi-whatsapp</v-icon>
            Compartilhar
          </v-btn>

        </v-container>
        <!-- End Modal Footer -->

      </v-container>
    </v-layout>
    <!-- End Modal Content -->

    <!-- Modal Envio de Documentos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog_documentos"
        :retain-focus="false"
        height="600px"
        min-height="600px"
        max-width="600px"
      >
        <v-card class="justify-end align-center">
          <ModalEnvioDocumentos />
        </v-card>
      </v-dialog>
    </v-row>
    <!-- End Modal Envio de Documentos -->

  </div>
</template>

<script type="text/javascript">
import store_usuario from "../../store/store_usuario"
import store_ModalNegociacao from "./store_ModalNegociacao"
import store_Negociacoes from "../Negociacoes/store_Negociacoes"
import store_ModalEnvioDocumentos from "../ModalEnvioDocumentos/store_ModalEnvioDocumentos";
import store_Usuarios from "../Usuarios/store_Usuarios"
import ModalEnvioDocumentos from "../ModalEnvioDocumentos/ModalEnvioDocumentos.vue";
import { PropostaRelatorio } from "../../services/global";

export default {
  name: "ModalPropostaSucesso",

  components: {
    ModalEnvioDocumentos,
  },

  data() {
    return {
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Negociacoes           : store_Negociacoes,
      store_ModalEnvioDocumentos  : store_ModalEnvioDocumentos,
      store_Usuarios              : store_Usuarios,

      loading                     : false,
      dialog_documentos           : false,
    };
  },
  
  mounted() {
    //this.store_Negociacoes.filtra_negociacoes_abertas();
  },

  methods : {
    abre_documentos(cod_opor, cod_fase) {
      this.store_ModalEnvioDocumentos.dados = {};
      this.store_ModalEnvioDocumentos.Abre_Documentos(cod_opor, cod_fase);
      this.dialog_documentos = true;
    },

    async propostaRelatorio(cod_opor) {
      this.loading = true;
      await PropostaRelatorio(cod_opor);
      this.loading = false;
    }
    
  }

};

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.intro {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem !important;
}

.container-check {
  width: 50px;
  height: 50px;
  background: #e9f0e7;
  border-radius: 50%;
}

.icon-check {
  font-size: 1.8rem !important;
}

.text {
  font-weight: 500;
  text-align: center;
  color: #00000099;
}

.btn-anexo {
  width: 100%;
  height: 2.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
  background: #274abb;
}

.icon {
  font-size: 1.3rem !important;
}
</style>
