<template>
  <div id="ModalLeadSucesso" class="p-0" style="min-height: 400px">
    <!-- Modal Content -->
    <v-layout row wrap class="modal-container w-100 ml-0 pt-4 px-4 mb-3">
      <v-container class="modal-wrapper w-100 py-6 rounded white">
        <h2
          align="center"
          style="color: #5cb460"
          class="intro font-weight-bold"
        >
        Lead {{store_ModalNegociacao.cod_opor}} gerado com <br class="d-flex d-sm-none"/>sucesso!
        </h2>
        <div
          class="container-check d-flex justify-center align-center mt-6 mx-auto"
        >
          <v-icon class="icon-check" color="green">mdi-check-bold</v-icon>
        </div>
      </v-container>

      <v-container class="text">
        <!-- <span class="text">
          Ela ficará ativa por um período de </span><span class="text-forte">{{store_ModalNegociacao.vencimento_dt_horas}}</span>
          <span class="text"> horas.<br>
          Com vencimento em </span>
          <span class="text-forte">{{formatDateTime(store_ModalNegociacao.vencimento_dt)}}</span>.<br><br>
          <span class="text">
          Após este período se você não efetivar a proposta, 
          o imóvel voltará a ficar disponível, podendo ser negociado com outra pessoa.
        </span> -->
        <!-- <span class="text">
          Para efetivar a reserva, acesse o menu "Minhas Negociações" na parte infeiro esquerda do site ou no menu do seu usuário.
        </span> -->

        <!-- Modal Footer -->
        <!-- <v-container class="px-4 text-none">
          <br>

          <v-btn
            class="btn-anexo text-white text-none"
            color="primary"
            v-on:click="ReservaRelatorio(store_ModalNegociacao.cod_opor)"
            :loading="loading"
          >
          <v-icon left dark>mdi-eye</v-icon>
            Visualizar Reserva
          </v-btn>

        </v-container> -->
        <!-- End Modal Footer -->

      </v-container>
    </v-layout>
    <!-- End Modal Content -->

  </div>
</template>

<script type="text/javascript">
import store_ModalNegociacao from "./store_ModalNegociacao"
import store_Negociacoes from "../Negociacoes/store_Negociacoes"
import { Relatorio } from "../../services/global"
import { formatDateTime } from "../../services/funcoes"

export default {
  name: "ModalLeadSucesso",

  data() {
    return {
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Negociacoes           : store_Negociacoes,
      loading                     : false,
      formatDateTime              : formatDateTime,
    };
  },

  mounted() {
    //this.store_Negociacoes.filtra_negociacoes_abertas();
  },

  methods : {
    async LeadRelatorio(cod_opor) {
      let data = {
        nome: "VENDA.LEAD",
        tipo: "html-pdf",
        parametros: [{ Name: "cod_opor", Value: cod_opor }],
      };

      this.loading = true;
      const ls_Resp = await Relatorio(data);
      this.loading = false;
    }
  }

};

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.intro {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem !important;
}

.container-check {
  width: 50px;
  height: 50px;
  background: #e9f0e7;
  border-radius: 50%;
}

.icon-check {
  font-size: 1.8rem !important;
}

.text {
  font-weight: 500;
  text-align: center;
  color: #00000099;
}

.btn-anexo {
  width: 100%;
  height: 2.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
  background: #274abb;
}

.text-forte {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px !important;
  color: #274abb
}

.icon {
  font-size: 1.3rem !important;
}
</style>
