<template>
  <v-container class="pa-0" id="ModalNegociacao">
    
    <!-- CABECALHO ------------------------------------------------------------------- -->
    <v-container class="pa-0">
      <v-toolbar-title elevation="0" style="height:129px">
        <ModalNegociacaoCabecalho class="pa-0" ref="ModalNegociacaoCabecalho" />
      </v-toolbar-title>
    </v-container>

    <!-- CORPO ----------------------------------------------------------------------- -->
    <v-container class="pa-0  modal-wrapper">

      <!-- Negociar -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalNegociar'" >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalNegociar ref="ModalNegociar"/>
        </transition>
      </v-container>

      <!-- Contato -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step == 'ModalSelecaoContato' && store_usuario.autenticado" >
          <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
            <ModalSelecaoContato ref="ModalSelecaoContato"/>
          </transition>
      </v-container>
      <!-- Selecao de pessoa pode usado em outras telas, por isso o parametro e passado como propriedade do componente ---- -->

      <!-- Pessoa -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalSelecaoPessoa' && store_usuario.autenticado"  >
          <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
            <ModalSelecaoPessoa ref="ModalSelecaoPessoa"
                                :ls_Anterior="['LEAD'].indexOf(store_ModalNegociacao.acao) >= 0 ? '' : 'ModalNegociar' "
                                :ls_Proximo="['LEAD', 'QUALIFICACAO', 'RESERVA', 'PROPOSTA', 'USUARIO'].indexOf(store_ModalNegociacao.acao) >= 0 ? 'ModalSelecaoCorretor' : 'ModalSelecaoPagamento' "/>
          </transition>
      </v-container>

      <!-- Corretor -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalSelecaoCorretor' && store_usuario.autenticado"  >
          <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
            <ModalSelecaoCorretor ref="ModalSelecaoCorretor"/>
          </transition>
      </v-container>

      <!-- Pagamento -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalSelecaoPagamento' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalSelecaoPagamento ref="ModalSelecaoPagamento"/>
        </transition>
      </v-container>

      <!-- Login -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'LoginContainerMapa' && !store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <LoginContainerMapa ref="LoginContainerMapa"/>
        </transition>
      </v-container>

      <!-- Lead Confirmacao -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalLeadConfirmacao' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalLeadConfirmacao ref="ModalLeadConfirmacao" />
        </transition>
      </v-container>
      <!-- Lead Sucesso -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalLeadSucesso' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalLeadSucesso ref="ModalLeadSucesso"/>
        </transition>
      </v-container>

      <!-- Reserva Confirmacao -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalReservaConfirmacao' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalReservaConfirmacao ref="ModalReservaConfirmacao"/>
        </transition>
      </v-container>
      <!-- Reserva Sucesso -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalReservaSucesso' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalReservaSucesso ref="ModalReservaSucesso"/>
        </transition>
      </v-container>

      <!-- Proposta Confirmacao -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalPropostaConfirmacao' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalPropostaConfirmacao ref="ModalPropostaConfirmacao"/>
        </transition>
      </v-container>
      <!-- Proposta Sucesso -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalPropostaSucesso' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalPropostaSucesso ref="ModalPropostaSucesso"/>
        </transition>
      </v-container>

      <!-- Simulação -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalOrcamentoSucesso' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalOrcamentoSucesso ref="ModalOrcamentoSucesso"/>
        </transition>
      </v-container>

      <!-- Situação Imovel -->
      <v-container class="pa-0" v-if="store_ModalNegociacao.step === 'ModalSituacaoImovel' && store_usuario.autenticado"  >
        <transition :name="store_ModalNegociacao.currentTransition" mode="out-in" style="overflow-x: hidden;">
          <ModalSituacaoImovel ref="ModalSituacaoImovel"/>
        </transition>
      </v-container>

    </v-container>

  </v-container>

</template>

<script>
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_usuario from "../../store/store_usuario";

import ModalNegociar from "./ModalNegociar.vue";
import LoginContainerMapa from "../Login/LoginContainerMapa.vue";
import ModalSelecaoPagamento from './ModalSelecaoPagamento.vue';
import ModalSelecaoContato from './ModalSelecaoContato.vue';
import ModalSelecaoPessoa from './ModalSelecaoPessoa.vue';
import ModalSelecaoCorretor from './ModalSelecaoCorretor.vue';
import ModalNegociacaoCabecalho from "./ModalNegociacaoCabecalho.vue";
import ModalLeadConfirmacao from "./ModalLeadConfirmacao.vue"
import ModalLeadSucesso from "./ModalLeadSucesso.vue"
import ModalReservaConfirmacao from "./ModalReservaConfirmacao.vue"
import ModalReservaSucesso from "./ModalReservaSucesso.vue"
import ModalPropostaConfirmacao from "./ModalPropostaConfirmacao.vue";
import ModalPropostaSucesso from "./ModalPropostaSucesso.vue"
import ModalOrcamentoSucesso from "./ModalOrcamentoSucesso.vue"
import ModalSituacaoImovel from "./ModalSituacaoImovel.vue"

export default {
  name: "ModalNegociacao",

  components: {
      ModalNegociacaoCabecalho,
      ModalNegociar,
      LoginContainerMapa,
      ModalSelecaoPagamento,
      ModalSelecaoContato,
      ModalSelecaoPessoa,
      ModalSelecaoCorretor,
      ModalLeadConfirmacao,
      ModalLeadSucesso,
      ModalReservaConfirmacao,
      ModalReservaSucesso,
      ModalPropostaConfirmacao,
      ModalPropostaSucesso,
      ModalOrcamentoSucesso,
      ModalSituacaoImovel,
  },
  
  data() {
    return {
      store_usuario         : store_usuario,
      store_ModalNegociacao : store_ModalNegociacao,
    }
  },

  mounted() {
    // console.log("ModalNegociacao - Dialog1111", this.store_ModalNegociacao.dialog_novo_lead);
  },

  created() {
    //console.log("ModalNegociacao - CREATE Dialog");
  },

  destroyed() {
    //console.log("ModalNegociacao - DESTROY");
  },

}

</script>

<style scoped>
  
  /* Próximo Quadro ------------------- */
  .next-leave-active {
    opacity: 0.7; }
  .next-enter-active {
    transition: 0.2s; }
  .next-enter {
    transform: translate(100%, 0); }
  .next-leave-to {
    transform: translate(-100%, 0); }

  /* Quadro Anterior ------------------ */
  .prev-leave-active {
    opacity: 0.7; }
  .prev-enter-active {
    transition: 0.2s; }
  .prev-enter {
    transform: translate(-100%, 0); }
  .prev-leave-to {
    transform: translate(100%, 0); }

  .modal-wrapper {
    overflow-y: auto;
    height: calc(100vh - 245px) !important;
    max-height: 470px !important;
  }

/* CELULAR */
/* @media (max-width: 599px) {
  .modal-wrapper {
    height: calc(100vh - 150px) !important;
  }

} */

</style>
