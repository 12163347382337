<template>
  <v-container id="ModalSelecaoContato" class="pa-0">

    <!-- Begin Componente Usuarios -->
    <v-container class="">
      <ContatoConsultaMobile/>
    </v-container>  
    <!-- End Componente Usuarios -->

    <!-- Modal Footer -->
    <v-footer absolute class="pa-2 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary"
             class="mr-2"
             v-on:click="store_ModalNegociacao.currentTransition='prev';store_ModalNegociacao.step = 'ModalNegociar'" 
             outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>

      <v-btn color="primary"
              class="ml-2"
              :disabled="!store_ModalNegociacao.contato_selecionado.cod_contato"
              v-on:click="OrcamentoInicial_Post();"
              :loading="loading"
      >
        <v-icon left>
          mdi-arrow-right
        </v-icon>
        Próximo
      </v-btn>
      <!-- v-on:click="validate" -->
    </v-footer>

    <!-- End Modal Footer -->
  </v-container>
 
</template>

<script type="text/javascript">
import store_site from "../../store/store_site"
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Usuarios from "../Usuarios/store_Usuarios";
import ContatoConsultaMobile from "../Contatos/ContatoConsultaMobile.vue"
import { OrcamentoPost } from "../../services/negociacoes"

export default {
  name: "ModalSelecaoContato",

  components: {
    ContatoConsultaMobile
  },

  data() {
    return {
      store_site                  : store_site,
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Usuarios              : store_Usuarios,                        

      timeOut                     : 0,
      loading                     : false,
    };
  },

  updated() {
    //this.store_ModalNegociacao.contato_selecionado = {};
  },

  methods: {
    async OrcamentoInicial_Post() {
      
      // Salvando a primeira fase do orçamento sem o plano mas com o contato
      if (this.store_ModalNegociacao.acao == 'ORCAMENTO') {
        var lo_Orcamento = {
                    "cod_contato": this.store_ModalNegociacao.contato_selecionado.cod_contato,
                    "cod_usuario": this.store_usuario.user.cod_usuario,
                    "cod_imovel": this.store_ModalNegociacao.dados.cod_imovel,
        }
        //console.log("ORCAMENTO INICIAL - RESP 1 ---  ", lo_Orcamento)
        const ls_Resp = await OrcamentoPost(lo_Orcamento);
        //console.log("ORCAMENTO INICIAL - RESP 2 ---  ", ls_Resp)
        if (ls_Resp.result.message == 'success') {
          this.store_ModalNegociacao.cod_opor       = ls_Resp.result.data.cod_opor;
          this.store_ModalNegociacao.cod_orcamento  = ls_Resp.result.data.cod_orcamento;
          //console.log("this.store_ModalNegociacao.cod_orcamento", this.store_ModalNegociacao.cod_orcamento)
          this.store_site.alert           = false;

          this.store_ModalNegociacao.currentTransition='next';
          //if (this.store_usuario.tipo_cliente == 'USUARIO')
          //  this.store_ModalNegociacao.step = 'ModalSelecaoCorretor'
          //else
          this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'

        }
        else {
          this.store_site.alert_cor       = "#FF0000",
          this.store_site.alert_timeout   = 10000,
          this.store_site.alert_msg       = ls_Resp.errors.trim();
          this.store_site.alert           = true;
          this.loading=false;
        }
      }
    }

  }

};

</script>

  <style scoped>
  *,
  html {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .modal-wrapper {
    overflow-y: auto;
    height: 400px;
  }

  .title-value {
    font-size: 18px !important;
    font-weight: 800;
    color: steelblue;
  }

  .title-value-light {
    font-size: 18px !important;
    font-weight: 500;
    color:steelblue;
  }

  .title-debt {
    font-size: 18px !important;
    font-weight: 800;
    color: #f03347;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .v-btn {
    width: 150px;
  }

  .card-row {
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    padding: 0px;
  }

  .card-title {
    font-size: 17px !important;
    font-weight: 600;
    text-transform: capitalize !important;
    color: #444;
  }

  .container-table-contact {
    background: aqua!important;
    padding: 20px;
  }

  .tabela {
    width: 400px!important;
    background: aqua!important;
  }

  .row-container-contact {
    width: 100%!important;
    padding: 20px!important;
    background: aqua!important;
  }

</style>
