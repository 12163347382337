import { render, staticRenderFns } from "./ModalOrcamentoSucesso.vue?vue&type=template&id=45e0547f&scoped=true"
import script from "./ModalOrcamentoSucesso.vue?vue&type=script&lang=js"
export * from "./ModalOrcamentoSucesso.vue?vue&type=script&lang=js"
import style0 from "./ModalOrcamentoSucesso.vue?vue&type=style&index=0&id=45e0547f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e0547f",
  null
  
)

export default component.exports