<template>
  <div id="ModalOrcamentoSucesso" class="p-0" style="min-height: 400px">
  
    <!-- Modal Content -->
    <v-layout row wrap class="modal-container w-100 ml-0 pt-4 px-4 mb-3">
      <v-container class="modal-wrapper w-100 py-0 rounded white">
        <h2
          align="center"
          style="color: #5cb460"
          class="intro font-weight-bold"
        >
          Simulação gerada com <br class="d-flex d-sm-none"/>sucesso!
        </h2>
        <div
          class="container-check d-flex justify-center align-center mt-6 mx-auto"
        >
          <v-icon class="icon-check" color="green">mdi-check-bold</v-icon>
        </div>
      </v-container>

      <v-container class="pt-7">
        <!-- <p class="text">
          Você acaba de receber um email com o documento de simulação,
        </p> -->
        <p class="text">
          Se quiser, você poderá visualizar e compartilhar a simulação, clicando no botão abaixo:
        </p>

        <br>

        <!-- Modal Footer -->
        <v-container class="px-4 text-none">
          <v-btn
            class="btn-zap text-white text-none text mb-3"
            color="success"
            v-on:click="OrcamentoRelatorio(store_ModalNegociacao.cod_orcamento)"
            :loading="loading"
          >
          <v-icon left dark>mdi-whatsapp</v-icon>
            Compartilhar
          </v-btn>


        </v-container>
        <!-- End Modal Footer -->

      </v-container>
    </v-layout>
    <!-- End Modal Content -->

  </div>
</template>

<script type="text/javascript">
import store_site from "../../store/store_site"
import store_ModalNegociacao from "./store_ModalNegociacao"
import { Relatorio, Zap } from "../../services/global"

export default {
  name: "ModalPropostaSucesso",

  data() {
    return {
      store_site            : store_site,
      store_ModalNegociacao : store_ModalNegociacao,
      loading               : false,
      loading_zap           : false,
      buttons: [
        {
          color: "info",
          title: "Infomation",
          type: "info"
        },
        {
          color: "success",
          title: "Success",
          type: "success"
        },
        {
          color: "warning",
          title: "Warning",
          type: "warning"
        },
        {
          color: "error",
          title: "Error",
          type: "error"
        }
      ],
      snackbar : {
            color: "info",
            icon: "mdi-whatsapp",
            mode: "multi-line",
            position: "top",
            timeout: 0,
            title: "Information",
            text:
              "This is useful and is quite a long message, and won't be hidden automatically. You need to dismiss this by clicking the 'X' on the right.",
            visible: true
          }
    };
  },

  mounted() {
    this.OrcamentoRelatorio(this.store_ModalNegociacao.cod_orcamento);
  },

  methods: {
    visualizar_simulacao() {
      //console.log('visualizar_simulacao');
    },

    async OrcamentoRelatorio(cod_orcamento) {
      let data = {
        nome: "VENDA.ORCAMENTO",
        tipo: "html-pdf",
        parametros: [{ Name: "cod_orcamento", Value: cod_orcamento }],
      };
      this.loading = true;
      const ls_Resp = await Relatorio(data);
      this.loading = false;
    },

    async OrcamentoZap(cod_orcamento, telefone) {
      let data = {
        nome: "VENDA.ORCAMENTO",
        tipo: "html-pdf",
        parametros: [{ Name: "cod_orcamento", Value: cod_orcamento }],
      };
      this.loading_zap = true;
      var ls_telefone_limpo = '55' + telefone.contato_celular.replace(/[^0-9]/g, '');
      const ls_Resp = await Zap(data, ls_telefone_limpo);
      if (ls_Resp && ls_Resp.data && ls_Resp.data.status == 'Mensagem multimidia enviada com sucesso!') {
        this.store_site.alert_cor       = green;
        this.store_site.alert_timeout   = 3000;
        this.store_site.alert_msg       = ls_Resp.data.status;
        this.store_site.alert           = true;
      }
      else {
        this.store_site.alert_cor       = yellow;
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Resp.data.status;
        this.store_site.alert           = true;
      }
      this.loading_zap = false;
    },

  }


};

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.intro {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem !important;
}

.container-check {
  width: 50px;
  height: 50px;
  background: #e9f0e7;
  border-radius: 50%;
}

.icon-check {
  font-size: 1.8rem !important;
}

.text {
  font-weight: 500;
  text-align: center;
  color: #00000099;
}

.btn-visualizar {
  width: 100%;
  height: 2.2rem;
  font-weight: 500;
  font-size: 0.9rem !important;
  background: #274abb;
}

.btn-zap {
  width: 100%;
  height: 2.2rem;
  font-weight: 500;
  font-size: 0.9rem !important;
  //background: success;
}

.icon {
  font-size: 1.3rem !important;
}
</style>
