<template>
  <v-container id="ModalSituacaoImovel" class="pa-0 pl-4 pr-4 modal-wrapper">
    
    <!-- Modal Content -->

      <v-container class="pt-2 ">
        <div class="d-flex justify-center mb-4">
          <span class="text"
                break>
            Situação do Imóvel
            <br>
          <span
            style="color: #3858c0"
            class="intro font-weight-bold"
          >
            {{ imovel_situacao }}
          </span>
          </span>
        </div>
        
        <span class="text mb-2">
          Escolha nova situação do Imóvel
        </span>

        <v-radio-group v-model="cod_situacao" class="mt-2">
          <v-radio
            v-for="item in situacoes"
            :key="item.cod_situacao"
            :label="item.situacao_nome"
            :value="item.cod_situacao"
          >
          </v-radio>

        </v-radio-group>
        
      </v-container>
    <!-- End Modal Content -->

    <!-- Modal Footer -->
    <v-footer absolute class="pa-3 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary"
             class="mr-2"
             v-on:click="store_ModalNegociacao.currentTransition='prev';store_ModalNegociacao.step = 'ModalNegociar'" 
             outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>

        <v-btn
            :disabled="cod_situacao <= 0"
            class="ml-2 btn-confirm"  
            v-on:click="validate"
            :loading="loading"
            color="success"
          >
          <!-- class="btn-confirm text-white text-none" -->
          <v-icon left dark>
            mdi-cloud-upload
          </v-icon>
            Salvar
        </v-btn>

      </v-footer>

  </v-container>
</template>

<script type="text/javascript">
import store_site from "../../store/store_site"
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";
import { OpcaoVendaGet } from "../../services/negociacoes"
import { SituacoesGet } from "../../services/global";
import { EventBus } from "../../services/eventbus";

import { ImovelPut } from "../../services/negociacoes"

export default {
  name: "ModalSituacaoImovel",

  data() {
    return {
      store_site                  : store_site,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Empreendimentos       : store_Empreendimentos,

      situacoes                   : [],
      
      valid                       : false,
      loading                     : false,
      cod_situacao                : null,
    };
  },

  computed: {
    imovel_situacao() {
      let situacao = this.situacoes.find((e) => e.cod_situacao == store_ModalNegociacao.dados.cod_situacao);
      if (situacao)
        return situacao.situacao_nome
      else
        return ""
    }
  },

  async created() {
    this.cod_situacao = null;
    const ls_Resp = await SituacoesGet();
    if (ls_Resp.length > 0) {
      ls_Resp.forEach((value) => {
        if (value.situacao_tipo == 'Mapa de Venda' && 
            (value.situacao_sistema == 'DISPONIVEL' ||
             value.situacao_sistema == 'INDISPONIVEL' ||
             value.situacao_sistema == 'RESERVADO' ||
             value.situacao_sistema == 'PRE RESERVADO' )
           )
          this.situacoes.push(value);
      });
    }
  },

  mounted() {
    this.valid = false;
  },

  methods: {
  
    async busca_situacao(p_cod_situacao) {
      let situacoes = await JSON.stringify(this.situacoes);
      situacoes = await JSON.parse(situacoes);
      if (p_cod_situacao) {
        let situacao = await situacoes.find((e) => e.cod_situacao == p_cod_situacao);
        if (situacao) {
          return situacao.situacao_nome;
        }
      }
    },

    async validate () {
 
      // Atualizando Imovel ---------------------
      this.loading = true;
      const lo_Imovel = {cod_imovel   : this.store_ModalNegociacao.dados.cod_imovel,
                         cod_situacao : this.cod_situacao};
      const ls_Resp = await ImovelPut(lo_Imovel);
      if (ls_Resp.result.message == 'success') {
        this.store_site.alert           = false;
        this.loading = false;
        this.store_ModalNegociacao.botoes = await OpcaoVendaGet(this.store_ModalNegociacao.dados.cod_imovel);
        this.store_ModalNegociacao.dialog = false;
        //EventBus.$emit("MapaLotes_FechaDialogo");
      }
      else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Resp.result.trim();
        this.store_site.alert           = true;
        this.loading=false;
      }

    },

  }

};

</script>

<style scoped>
  *,
  html {
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .modal-container {
    margin-bottom: 54px;
  }

  .intro {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem !important;
  }

  .text {
    font-weight: 500;
    text-align: center;
    color: #00000099;
  }

  .wrapper {
    margin-top: 54px;
  }

  .container-radio {
    width: 90px;
    height: 35px;
    position: relative;
    border-radius: 5px;
  }

  .container-radio label {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding-top: 5px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #d6d6d6;
    color: #262b30de;
    background: #fff no-repeat center center;
  }

  .wrapper input:focus + label {
    border: 0.5px solid #3858c0;
    background: #3858c0;
    color: #fff;
  }

  .wrapper input:checked + label {
    border: 0.5px solid #3858c0;
    background: #3858c0;
    color: #fff;
  }

  .btn-confirm {
    width: 150px;
  }

  .icon {
    font-size: 1.3rem !important;
  }

  .modal-wrapper {
    max-height: 340px;
  }

</style>
