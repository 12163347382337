<template>
  <v-container id="ModalPropostaConfirmacao" class="pa-0" style="min-height: 400px">

    <!-- Modal Content -->
    <v-layout row wrap class="modal-container w-100 ml-0 pt-4 px-4">

      <v-container class="modal-wrapper w-100 mt-3 rounded white">
        <h2 align="center" class="pa-0 mt-n2" v-bind:style="{ 'color':COR_PRINCIPAL }" >Confirmação da Proposta</h2><br>
      </v-container>

      <v-container class="modal-wrapper w-100 mt-n3 rounded white">
        <form class="wrapper justify-center">
          <div style="width:100%">            

            <DatePicker
              v-model="dtVenda"
              id="dtVenda"
              ref="dtVenda"
              label="Data da Venda"
              placeholder="dia/mês/ano"
              outlined
              backgroundColor="#FFF"
              dense
              class="mt-n2"
              >
            </DatePicker>
          </div>

          <div class="mt-n3" style="width:100%; height: 80px;">
            <span class="text">Escolha o dia de vencimento das parcelas.</span>
            <v-btn-toggle v-model="dia_vencimento" color="primary">
              <v-btn v-if="plano_venda_1_opcaodiavencto" :value="plano_venda_1_opcaodiavencto" class="btn-radio">
                <span class="btn-radio-label">{{ plano_venda_1_opcaodiavencto }}</span>
              </v-btn>
              <v-btn v-if="plano_venda_2_opcaodiavencto" :value="plano_venda_2_opcaodiavencto" class="btn-radio">
                <span class="btn-radio-label">{{ plano_venda_2_opcaodiavencto }}</span>
              </v-btn>
              <v-btn v-if="plano_venda_3_opcaodiavencto" :value="plano_venda_3_opcaodiavencto" class="btn-radio">
                <span class="btn-radio-label">{{ plano_venda_3_opcaodiavencto }}</span>
              </v-btn>
              <v-btn v-if="plano_venda_4_opcaodiavencto" :value="plano_venda_4_opcaodiavencto" class="btn-radio">
                <span class="btn-radio-label">{{ plano_venda_4_opcaodiavencto }}</span>
              </v-btn>
              <v-btn v-if="plano_venda_5_opcaodiavencto" :value="plano_venda_5_opcaodiavencto" class="btn-radio">
                <span class="btn-radio-label">{{ plano_venda_5_opcaodiavencto }}</span>
              </v-btn>
            </v-btn-toggle>

            <!-- {{ dia_vencimento }}
            <v-radio-group v-model="dia_vencimento" row>
              <div v-if="plano_venda_1_opcaodiavencto" class="container-radio">
                <input v-model="dia_vencimento" :key="plano_venda_1_opcaodiavencto" :value="plano_venda_1_opcaodiavencto" type="radio" ref="dia1" id="dia1" name="dia1"/>
                <label for="dia1">{{ plano_venda_1_opcaodiavencto }}</label>
              </div>
              <div v-if="plano_venda_2_opcaodiavencto" class="container-radio">
                <input v-model="dia_vencimento" :value="plano_venda_2_opcaodiavencto" name="dia10" type="radio" id="dia10" ref="dia10" />
                <label for="dia10">{{ plano_venda_2_opcaodiavencto }}</label>
              </div>
              <div v-if="plano_venda_3_opcaodiavencto" class="container-radio">
                <input v-model="dia_vencimento" :value="plano_venda_3_opcaodiavencto" name="dia15" type="radio" id="dia15" ref="dia15" />
                <label for="dia15">{{ plano_venda_3_opcaodiavencto }}</label>
              </div>
              <div v-if="plano_venda_4_opcaodiavencto" class="container-radio">
                <input v-model="dia_vencimento" :value="plano_venda_4_opcaodiavencto" name="dia20" type="radio" id="dia20" ref="dia20" />
                <label for="dia20">{{ plano_venda_4_opcaodiavencto }}</label>
              </div>
              <div v-if="plano_venda_5_opcaodiavencto" class="container-radio">
                <input v-model="dia_vencimento" :value="plano_venda_5_opcaodiavencto" name="dia25" type="radio" id="dia25" ref="dia25" />
                <label for="dia25">{{ plano_venda_5_opcaodiavencto }}</label>
              </div>
            </v-radio-group>
            -->
          </div>

          <div class="mt-n3" style="height:100px; width:100%; text-align:center ">
            <v-textarea
              v-model="observacao"
              background-color="#FFF"
              label="Observação"
              placeholder="Observação"
              filled
              dense
              outlined
              rows="3"
              :rules="observacaoRules"
            ></v-textarea>
          </div>

        </form>

        <!-- Modal Footer -->
        <v-container class="px-4 text-none">
          <v-btn
            class="btn-confirm text-white text-none mt-2"
            v-on:click="validate"
            :disabled="!dia_vencimento || !dtVenda"
            :loading="loading"
            color="success"
          >
          <v-icon left dark>
            mdi-cloud-upload
          </v-icon>
            Confirmar Proposta
          </v-btn>
        </v-container>
        <!-- End Modal Footer -->

      </v-container>
    </v-layout>
    <!-- End Modal Content -->

    <!-- Modal Footer -->
    <!-- Modal Footer -->
    <v-footer absolute class="pa-2 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary" v-on:click="store_ModalNegociacao.currentTransition='prev';store_ModalNegociacao.step = 'ModalSelecaoPagamento'" outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>
    </v-footer>
    <!-- End Modal Footer -->

  </v-container>
</template>

<script type="text/javascript">
import moment from 'moment';
import store_site from "../../store/store_site"
import store_usuario from "../../store/store_usuario";
import { PropostaRelatorio } from "../../services/global";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";
import { PropostaPost } from "../../services/negociacoes"
import store_Usuarios from "../Usuarios/store_Usuarios"
import { COR_PRINCIPAL } from "../../services/constantes";
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";

export default {
  name: "ModalPropostaConfirmacao",

  components: {
    DatePicker,
  },

  data() {
    return {
      store_site                  : store_site,
      store_Usuarios              : store_Usuarios,
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Empreendimentos       : store_Empreendimentos,

      COR_PRINCIPAL               : COR_PRINCIPAL,
      
      valid                       : false,
      loading                     : false,
      dia_vencimento              : null,
      dtVenda                     : null,
      observacao                  : null,

      plano_venda_1_opcaodiavencto  : null,
      plano_venda_2_opcaodiavencto  : null,
      plano_venda_3_opcaodiavencto  : null,
      plano_venda_4_opcaodiavencto  : null,
      plano_venda_5_opcaodiavencto  : null,

      observacaoRules: [
        (value) =>
          (!value || value.length <= 250) || "Observação deve ter menos do que 250 caracteres",
      ],

    };
  },

  created() {
    moment.locale('pt-br');
    moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSS-0300';
  },

  mounted() {
    this.valid = false;
    if (!this.store_ModalNegociacao.JSON_FORM.plano_venda_1_opcaodiavencto &&
        !this.store_ModalNegociacao.JSON_FORM.plano_venda_2_opcaodiavencto &&
        !this.store_ModalNegociacao.JSON_FORM.plano_venda_3_opcaodiavencto &&
        !this.store_ModalNegociacao.JSON_FORM.plano_venda_4_opcaodiavencto &&
        !this.store_ModalNegociacao.JSON_FORM.plano_venda_5_opcaodiavencto) {
        this.plano_venda_1_opcaodiavencto = "05";
        this.plano_venda_2_opcaodiavencto = "10";
        this.plano_venda_3_opcaodiavencto = "15";
        this.plano_venda_4_opcaodiavencto = "20";
        this.plano_venda_5_opcaodiavencto = "25";
      }
      else {
        this.plano_venda_1_opcaodiavencto = this.store_ModalNegociacao.JSON_FORM.plano_venda_1_opcaodiavencto;
        this.plano_venda_2_opcaodiavencto = this.store_ModalNegociacao.JSON_FORM.plano_venda_2_opcaodiavencto;
        this.plano_venda_3_opcaodiavencto = this.store_ModalNegociacao.JSON_FORM.plano_venda_3_opcaodiavencto;
        this.plano_venda_4_opcaodiavencto = this.store_ModalNegociacao.JSON_FORM.plano_venda_4_opcaodiavencto;
        this.plano_venda_5_opcaodiavencto = this.store_ModalNegociacao.JSON_FORM.plano_venda_5_opcaodiavencto;
      }
  },

  // watch : {
  //   dtVenda(val) {
  //     //console.log('WATCH::', val);
  //   }
  // },

  methods: {
  
    async validate () {
      
      this.store_ModalNegociacao.JSON_FORM.orcamento.dia_vencimento       = this.dia_vencimento;
      this.store_ModalNegociacao.JSON_FORM.orcamento.observacao           = this.observacao;

      //===== ESTUDAR
      //https://momentjs.com/timezone/docs/
      //zone.utcOffset(timestamp); // 480

      //this.dtVenda = moment(this.dtVenda, 'DD/MM/YYYY').format();

      this.store_ModalNegociacao.JSON_FORM.orcamento.data_venda           = this.dtVenda;
      
      // calculando a data da primeira parcela -------------------------------------
      let dt_aux = null;
      let li_quant = 0;
      
      // Pega o primeiro dia de vencimento escolhido
      if (this.dia_vencimento.length == 10)
        dt_aux = moment(this.dia_vencimento,'DD/MM/YYYY')
      else if (this.dia_vencimento.length == 2) {
        dt_aux = moment().set("date", this.dia_vencimento);
        if (dt_aux.format() < moment().format())
          dt_aux = moment(dt_aux).add(1, 'M').format();
        else
          dt_aux = moment(dt_aux).format();  
      }

      // Varrendo todas as datas e aplicando a data correta para cada tipo de periodo (SINAL, ENTRADA, PARCELA MENSAL)
      // SINAL ------------
      await this.store_ModalNegociacao.JSON_FORM.orcamento.planos[0].parcelas.forEach(element => {
        if (element.tipo == 'SINAL' && element.quantidade > 0) {
          const dt_sinal = moment(this.store_ModalNegociacao.JSON_FORM.orcamento.data_venda).format()
          this.store_ModalNegociacao.JSON_FORM.orcamento.data_sinal = moment(dt_sinal).format();
          element.data_vencto_inicial = moment(dt_sinal).format();
          //console.log('SINAL:::::', dt_sinal)
        }
      });
      // ENTRADA ------------
      await this.store_ModalNegociacao.JSON_FORM.orcamento.planos[0].parcelas.forEach(element => {
      if (element.tipo == 'ENTRADA' && element.quantidade > 0) {
          dt_aux = moment(dt_aux).add(li_quant, 'M').format();
          dt_aux = moment(dt_aux).set("date", this.dia_vencimento);
          //console.log('ENTRADA:::::', dt_aux)
          this.store_ModalNegociacao.JSON_FORM.orcamento.data_entrada_inicial = moment(dt_aux).format();
          element.data_vencto_inicial = moment(dt_aux).format();
          li_quant = element.quantidade;
        }
      });
      // PARCELA ------------
      await this.store_ModalNegociacao.JSON_FORM.orcamento.planos[0].parcelas.forEach(element => {
        if (element.tipo == 'PARCELA' && element.quantidade > 0) {
          dt_aux = moment(dt_aux).add(li_quant, 'M').format();
          dt_aux = moment(dt_aux).set("date", this.dia_vencimento);
          //console.log('PARCELA:::::', dt_aux)
          this.store_ModalNegociacao.JSON_FORM.orcamento.data_parcela_inicial = moment(dt_aux).format();
          element.data_vencto_inicial = moment(dt_aux).format();
        }
      });

      // Enviando Proposta ---------------------
      // console.log('JSON:::',
      //             li_quant,
      //             this.store_ModalNegociacao.JSON_FORM.orcamento.data_entrada_inicial,
      //             this.store_ModalNegociacao.JSON_FORM.orcamento.data_parcela_inicial,
      //             this.store_ModalNegociacao.JSON_FORM);
      this.loading = true;
      const ls_Resp = await PropostaPost(this.store_ModalNegociacao.JSON_FORM);
      if (ls_Resp.result.message == 'success') {
        this.store_ModalNegociacao.cod_opor           = ls_Resp.result.data.cod_opor;
        this.store_ModalNegociacao.cod_empreendvenda  = ls_Resp.result.data.cod_empreendvenda;
        this.store_site.alert                         = false;
        this.store_ModalNegociacao.currentTransition  = 'next';
        this.store_ModalNegociacao.step               = 'ModalPropostaSucesso';
        PropostaRelatorio(this.store_ModalNegociacao.cod_opor,
                          "Younect-Imob - Nova Proposta: " + this.store_ModalNegociacao.dados.empreend_nome + ' (Q:' + this.store_ModalNegociacao.dados.quadra+ ' / L:' + this.store_ModalNegociacao.dados.lote + ') n°: ' + this.store_ModalNegociacao.cod_empreendvenda)
      }
      else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Resp.errors.trim();
        this.store_site.alert           = true;
        this.loading=false;
      }

    },

  }

};

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-container {
  margin-bottom: 100px;
}

.intro {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem !important;
}

.text {
  font-size: 15px !important;
  text-align: center;
  color: #00000099;
}

.wrapper {
  margin-top: 20px;
}

.btn-radio {
  position: relative;
  // width: 95px;
  height: 30px!important;
  border-radius: 5px;
  margin-bottom: 15px;
  //color: red;
}

.btn-radio-label {
  font-size: 12px!important;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.container-radio {
  width: 95px;
  height: 30px;
  position: relative;
  border-radius: 5px;
  margin: 2px;
}

.container-radio label {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding-top: 3px;
  font-size: 14px!important;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #d6d6d6;
  color: #262b30de;
  background: #fff no-repeat center center;
}

.wrapper input:focus + label {
  border: 0.5px solid #3858c0;
  background: #3858c0;
  color: #fff;
}

.wrapper input:checked + label {
  border: 0.5px solid #3858c0;
  background: #3858c0;
  color: #fff;
}

@-moz-document url-prefix() {
  .wrapper input:checked + label:after {
    bottom: 0;
    right: 0;
    background-color: #21b4d0;
  }
}

.btn-confirm {
  width: 100%;
  height: 2.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
  background: #274abb;
}

.icon {
  font-size: 1.3rem !important;
}

.btn .btn__content:before {
  background-color: red
}

</style>
