<template>
  <v-container id="ModalReservaConfirmacao" class="pt-7 pl-4 pr-4 modal-wrapper">

    <!-- Modal Content -->
    <h2
      align="center"
      style="color: #3858c0"
      class="intro font-weight-bold"
    >
      Confirmação da Reserva
    </h2>

    <p class="text pt-7">
      Atenção !<br/>
      A reserva ficará disponível pelo período estipulado pelo Administrador do Sistema.<br/>
      Após este período, a reserva será cancelada e o Imóvel será disponibilizado para outra venda.<br/>
      Caso necessite prorrogar este prazo, converse com o seu Gestor.
    </p>
    <!-- End Modal Content -->

        <!-- Modal Footer -->
        <v-container class="px-4 text-none">
          <v-btn
            class="btn-confirm text-white text-none mt-2"
            v-on:click="validate"
            :loading="loading"
            color="success"
          >
          <v-icon left dark>
            mdi-cloud-upload
          </v-icon>
            Confirmar Reserva
          </v-btn>
        </v-container>
        <!-- End Modal Footer -->


    <!-- Modal Footer -->
    <v-footer absolute class="pa-3 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary" v-on:click="store_ModalNegociacao.currentTransition='prev';store_ModalNegociacao.step = 'ModalSelecaoCorretor'" outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>
    </v-footer>
    <!-- End Modal Footer -->

  </v-container>
</template>

<script type="text/javascript">
import { ReservaPost } from "../../services/negociacoes"

import store_site from "../../store/store_site"
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";

export default {
  name: "ModalPropostaConfirmacao",

  data() {
    return {
      store_site                  : store_site,
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Empreendimentos       : store_Empreendimentos,
      
      valid                       : false,
      loading                     : false,
    };
  },

  mounted() {
    this.valid = false;
  },

  methods: {
  
    async validate () {
 
      this.store_ModalNegociacao.JSON_FORM = {cod_imovel  : this.store_ModalNegociacao.dados.cod_imovel,
	                                            //cod_pessoa : this.store_usuario.user.cod_pessoa,
                                              cod_pessoa  : this.store_ModalNegociacao.pessoa_selecionado.cod_pessoa,
                                              cod_usuario : this.store_ModalNegociacao.corretor_selecionado.cod_usuario,
                                              corretores  : [{cod_usuario : this.store_ModalNegociacao.corretor_selecionado.cod_usuario }]
                                             }
      // Enviando Reserva ---------------------
      this.loading = true;
      const ls_Resp = await ReservaPost(this.store_ModalNegociacao.JSON_FORM);
      if (ls_Resp.result.message == 'success') {
        //'reserva_periodo','vencimento_dt'
        this.store_ModalNegociacao.cod_opor             = ls_Resp.result.data.cod_opor;
        this.store_ModalNegociacao.vencimento_dt        = ls_Resp.result.data.vencimento_dt;
        this.store_ModalNegociacao.vencimento_dt_horas  = ls_Resp.result.data.vencimento_dt_horas;
        this.store_site.alert           = false;
        this.store_ModalNegociacao.currentTransition='next';
        this.store_ModalNegociacao.step = 'ModalReservaSucesso';
      }
      else {
        this.store_site.alert_cor       = "#FF0000",
        this.store_site.alert_timeout   = 10000,
        this.store_site.alert_msg       = ls_Resp.result.trim();
        this.store_site.alert           = true;
        this.loading=false;
      }

    },

  }

};

</script>

<style scoped lang="scss">
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-container {
  margin-bottom: 100px;
}

.intro {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem !important;
}

.text {
  font-weight: 500;
  text-align: center;
  color: #00000099;
}

.wrapper {
  margin-top: 54px;
}

.container-radio {
  width: 90px;
  height: 35px;
  position: relative;
  border-radius: 5px;
}

.container-radio label {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding-top: 5px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #d6d6d6;
  color: #262b30de;
  background: #fff no-repeat center center;
}

.wrapper input:focus + label {
  border: 0.5px solid #3858c0;
  background: #3858c0;
  color: #fff;
}

.wrapper input:checked + label {
  border: 0.5px solid #3858c0;
  background: #3858c0;
  color: #fff;
}

@-moz-document url-prefix() {
  .wrapper input:checked + label:after {
    bottom: 0;
    right: 0;
    background-color: #21b4d0;
  }
}

.btn-confirm {
  width: 100%;
  height: 2.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
  background: #274abb;
}

.icon {
  font-size: 1.3rem !important;
}

  .modal-wrapper {
    overflow-y: auto;
    max-height: 340px;
  }

</style>
